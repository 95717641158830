<template>
  <div>
    <CRow>
      <CCol md="12">
        <div style="float: right; text-align: right;" class="d-none d-sm-block">
          <h2 style="font-size: 1.5rem">Vigencia: {{challenge.end_date | date}}</h2>
          <h5 style="font-size: 1.0rem; font-weight: bold;">{{challenge.complete ? 'COMPLETADO' : 'NO COMPLETADO'}}</h5>
        </div>
        <br />
        <h1 v-if="challenge.type != 'boxes'" style="font-size: 1.5rem; text-align: center; width: 100%;">{{challenge.name}}</h1>
        <h5 style="font-size: 1.5rem; text-align: center; width: 100%;" v-if="!challenge.complete">{{challenge.description}}</h5>
        <h5 style="font-size: 1.0rem; text-align: center; width: 100%;" v-if="challenge.awards != undefined && challenge.awards.length == 1">
        <br />
        <br />
        <b>
        {{
          challenge.awards[0].type == 'cash'
          ? 
            'DINERO EN EFECTIVO: ' 
          : 
            (
              challenge.awards[0].type == 'ticket' 
              ? 
              'BOLETO PARA EVENTO: ' 
              : 
                (
                  challenge.awards[0].type == 'wallet' 
                  ?
                  'MONEDERO ELECTRÓNICO: ' 
                  :
                    (
                      challenge.awards[0].type == 'gift' 
                      ?
                      'PREMIO: ' 
                      :
                        (
                          challenge.awards[0].type == 'card' 
                          ?
                          'TARJETA DE REGALO: ' 
                          :
                            (
                              challenge.awards[0].type == 'event_points' 
                              ?
                              'PUNTOS PARA EVENTOS' 
                              :
                              (
                                challenge.awards[0].type == 'card_points' 
                                ?
                                'PUNTOS PARA CERTIFICACOS' 
                                :
                                (
                                  challenge.awards[0].type == 'gift_points' 
                                  ?
                                  'PUNTOS PARA PREMIOS' 
                                  :
                                  (
                                    challenge.awards[0].type == 'wallet_points' 
                                    ?
                                    'PUNTOS PARA MONEDERO' 
                                    :
                                    ''
                                  )
                                )
                              )
                            )
                        )
                    )
                )
            )
        }}
        </b>
        <br /><br />
        {{challenge.awards[0].description}}</h5>
        <br /><br />
        <h3 style="font-size: 12px; text-align: center;" v-if="challenge.complete"><br /><br />Has completado este concurso<br /><br /></h3>
        <!-- Video -->
        <CRow v-if="challenge.type == 'video'">
          <CCol md="12" style="text-align: center;">
            <div v-show="video.quest == false">
              <CButton color="info" size="sm" v-for="(video, index) in challenge.videos" :key="index" style="margin-right: 5px;" @click="changeVideo(video.youtube_reference)">Video {{index + 1}}</CButton>
              <br /><br />
              <div id="video-player-container" style="text-align: center;">
                <div v-if="video.quest == false" id="video-player-large" style="width:600px; margin: auto;" class="d-none d-xxl-block d-xl-block d-lg-block d-md-block"></div>
                <div v-if="video.quest == false" id="video-player-small" style="width:100%" class="d-xl-none d-xxl-none d-lg-none d-md-none"></div>
              </div>
              <br /><br />
              <CButton v-if="!challenge.complete" color="info" size="lg" style="margin-right: 5px;" @click="initQuest()">Iniciar Cuestionario</CButton>
              <br /><br /><br />
            </div>
            <div v-if="video.quest == true" style="text-align: left;">
              <CRow v-if="challenge.type == 'video'">
                <CCol md="12">
                  <div v-for="(question, q) in video.questions">
                    <strong>{{question.question}}</strong><br /><br />
                    <div v-if="question.type == 'options'" v-for="(option, o) in question.options.split(',')">
                      <input type="radio" :name="'question_'+question.id" @click="setAnswer(q, o, option)" style="margin: 10px;"> {{option}}
                    </div>
                    <div v-if="question.type == 'text'">
                      <input type="text" :name="'question_'+question.id" v-model="video.questions[q].supplier_answer" style="margin: 0px; width: 100%;">
                    </div>
                    <div v-if="question.type == 'file'">
                      <CButton color="info" size="sm" @click="uploadModal(challenge, question, q)" style="margin-right: 10px;">Cargar Archivo</CButton>
                    </div>
                    <br />
                  </div>
                  <div style="text-align: center;">
                    <br /><br />
                    <CButton color="info" size="lg" style="margin: auto;" @click="finishQuest()">Enviar</CButton>
                    <br /><br />
                  </div>
                </CCol>
              </CRow>
            </div>
          </CCol>
        </CRow>
        <CRow v-if="challenge.type == 'boxes'">
          <CCol md="12" style="text-align: center;" v-if="challenge.mechanic == 'total'">
          <div style="font-size: 14px; text-align: center; width: 100%; font-weight: bold;" v-if="progress.won > 0">{{progress.won}}<br />Puntos Ganados<br /><br /></div>
            <CProgress :value="progress.rate" style="border: 1px solid; height: 50px; width: 100%; text-align: center;" />
            <div style="font-size: 12px; text-align: center; width: 100%;"><br />{{progress.sold}} de {{progress.goal}}<br />Cajas Vendidas</div>
          </CCol>
          <CCol md="12" style="text-align: center;" v-if="challenge.mechanic == 'individual'">
            <div style="font-size: 14px; text-align: center; width: 100%; font-weight: bold;" v-if="progress.won > 0">{{progress.won}}<br />Puntos Ganados<br /><br /></div>
            <CProgress :value="progress.rate" style="border: 1px solid; height: 50px; width: 100%; text-align: center;" />
            <div style="font-size: 14px; text-align: center; width: 100%;"><br />{{progress.sold % progress.goal}} de {{progress.goal}}<br />Cajas Vendidas<br />+ {{progress.wins > 0 ? progress.award / progress.wins : progress.award}} Puntos</div>
          </CCol>
          <CCol md="12" style="text-align: center;" v-if="challenge.mechanic == 'ranges'">
            <div style="font-size: 14px; text-align: center; width: 100%; font-weight: bold;" v-if="progress.won > 0">{{progress.won}}<br />Puntos Ganados<br /><br /></div>
            <CProgress :value="progress.rate" style="border: 1px solid; height: 50px; width: 100%; text-align: center;" />
            <div style="font-size: 14px; text-align: center; width: 100%;"><br />{{progress.sold > 0 ? progress.sold % progress.goal : 0}}<span v-if="!progress.completed"> de {{progress.goal}}</span><br />Cajas Vendidas</div>

            <div style="font-size: 14px; text-align: center; width: 100%;">
            <br />Rangos de Ventas<br /><br />
              <table style="width: 100%;">
                <tr>
                  <th style="border: 1px solid;">Cajas</th>
                  <th style="border: 1px solid;">Puntos</th>
                </tr>
                <tr v-for="(range, index) in progress.ranges" :key="'r_'+index">
                  <td style="border: 1px solid;">{{range.min}}<span v-if="range.max < 999999"> - {{range.max}}</span><span v-if="range.max >= 999999">+</span></td>
                  <td style="border: 1px solid;">{{range.award}}</td>
                </tr>
              </table>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <FileUpload ref="uploadModal" @upload="upload"></FileUpload>
  </div>
</template>
<script>

import store from '../../../store'
import challenges_ws from '../../../services/challenges';

import FileUpload from './FileUpload.vue'

import YouTubePlayer from 'youtube-player';

export default {
  name: 'ChallengeView',
  components: {
    FileUpload
  },
  data () {
    return {
      challenge_id: 0,
      challenge: {
        'type': 'boxes'
      },
      player: false,
      screen: "normal",
      video: {
        player_1: false,
        player_2: false,
        quest: false,
        completed: false,
        questions: [],
        rate: 100
      },
      progress: false
    }
  },
  mounted: async function() {
    if(window.screen.width <= 768){
      this.screen = "mobile";
    }

    let challenge_id = await this.$route.params.challenge_id;
    this.challenge_id = challenge_id;

    let response = await challenges_ws.show(this.challenge_id); 

    if(response.type == "success"){
      this.challenge = response.data;

      if(this.challenge.type == "video"){
        let _this = this;
        this.video.rate = this.challenge.videos[0].rate;
        setTimeout(function(){
          _this.video.player_1 = YouTubePlayer('video-player-large');
          _this.video.player_1.loadVideoById(_this.challenge.videos[0].youtube_reference);
          _this.video.player_1.stopVideo();
        }, 100);

        setTimeout(function(){
          _this.video.player_2 = YouTubePlayer('video-player-small');
          _this.video.player_2.loadVideoById(_this.challenge.videos[0].youtube_reference);
          _this.video.player_2.stopVideo();
        }, 100);
      } 

      if(this.challenge.type == 'boxes'){
        let response2 = await challenges_ws.getProgress(this.challenge_id); 

        if(response2.type == "success"){
          this.progress = response2.data;
        }
      }
    }
  },
  methods: {
    changeVideo (youtube) {
      this.video.player_1.loadVideoById(youtube);
      this.video.player_1.stopVideo();

      this.video.player_2.loadVideoById(youtube);
      this.video.player_2.stopVideo();
    },
    async initQuest () {
      this.video.quest = true;

      document.getElementById("video-player-large").remove();
      document.getElementById("video-player-small").remove();

      let response = await challenges_ws.getQuestions(this.challenge_id); 

      if(response.type == "success"){
        this.video.questions = response.data;
      }
    },
    async setAnswer (q, o, answer) {
      let options = this.video.questions[q].options.split(",");
      this.video.questions[q].supplier_answer = options[o];
    },
    async finishQuest () {
      let params = {
        challenge_id: this.challenge_id,
        questions: this.video.questions
      }

      let response = await challenges_ws.saveAnswers(params); 

      if(response.type == "success"){
        this.video.quest = false;
        this.video.completed = true;
        this.challenge.complete = true;

        let _this = this;

        if(this.screen == "normal"){
          let div1 = document.createElement("div");
          div1.id = "video-player-large";
          div1.style.width = "600px";
          div1.class = "d-none d-xxl-block d-xl-block d-lg-block d-md-block"

          document.getElementById("video-player-container").appendChild(div1);

          setTimeout(function(){
            _this.video.player_1 = YouTubePlayer('video-player-large');
            _this.video.player_1.loadVideoById(_this.challenge.videos[0].youtube_reference);
            _this.video.player_1.stopVideo();
          }, 100);
        }
        else{
          let div2 = document.createElement("div");
          div2.id = "video-player-small";
          div2.style.width = "100%";
          div2.class = "d-xl-none d-xxl-none d-lg-none d-md-none"

          document.getElementById("video-player-container").appendChild(div2);

          setTimeout(function(){
            _this.video.player_2 = YouTubePlayer('video-player-small');
            _this.video.player_2.loadVideoById(_this.challenge.videos[0].youtube_reference);
            _this.video.player_2.stopVideo();
          }, 100);
        }
      }

      this.showToast(response.type, response.message);
    },
    uploadModal (challenge, question, index) {
      question.index = index;
      this.$refs.uploadModal.show("Cargar Archivo", challenge, question);
    },
    async upload (challenge, question, s3_file) {
      this.video.questions[question.index].supplier_answer = s3_file;
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    async finished (){
      this.loading();
      
      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
